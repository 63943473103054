import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export const constantRouterMap = [
  {
    path: '/',
    component: (resolve) => require(['@/views/home'], resolve),
    hidden: true
  },
  {
    path: '/cityCall/customer',
    component: (resolve) => require(['@/views/cityCall/customer'], resolve),
    hidden: true
  },
  {
    path: '/cityCall/mechanic',
    component: (resolve) => require(['@/views/cityCall/mechanic'], resolve),
    hidden: true
  },
  {
    path: '/cityCall/rider',
    component: (resolve) => require(['@/views/cityCall/rider'], resolve),
    hidden: true
  },
]

export default new Router({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap
})
